/* Comment */


@keyframes slider-animation {
    
    0% {
        opacity: 0;
    }
    6% {
        opacity: 1;
    }
    24% {
        opacity: 1;
    }
    30% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

.background-img-wrapper{
    padding: 450px 10% 415px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
}
.backgroundImageWrapper2, .backgroundImageWrapper3, .backgroundImageWrapper4 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    transition: ease-in 150ms;
}

/* Animations */
.backgroundImageWrapper4, .backgroundImageWrapper2, backgroundImageWrapper3 {
    opacity: 0;
}
.backgroundImageWrapper2.hide, .backgroundImageWrapper3.hide, .backgroundImageWrapper4.hide {
    opacity: 0;
    transition: ease-out 650ms;
}

.backgroundImageWrapper2.show, .backgroundImageWrapper3.show, .backgroundImageWrapper4.show {
    opacity: 1;
    transition: ease-in 400ms;
}
.tagline-div {
    width: 100%;
    height: 21px;
    position: relative;
}

.tagline.slide.show, .home-main-background-img-contents .main-text.show {
    opacity: 1;
    transition: all ease-in 500ms;
    transition-delay: 700ms;
}
.tagline.slide.hide, .home-main-background-img-contents .main-text.hide {
    opacity: 0;
    transition: ease-out 450ms;

}
.home-main-background-img-contents .main-text.show {
    opacity: 1;
    transition: all ease-in 450ms;
    transition-delay: 500ms;
}
 .home-main-background-img-contents .main-text.hide {
    opacity: 0;
    transition: ease-out 400ms;

}
.tagline.slide {
    opacity: 0;
}
.home-main-background-img-contents .tagline{
    font-size: 18px;
    font-weight: 600;
    position: absolute;
    top: 0;
    left: 0;
}
.backgroundTextBackground {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #18213567;
    display: flex;
    align-items: center;
    padding: 0 10%;
}
.home-main-background-img-contents {
    width: 100%;
    color: #fff;
}
.home-main-background-img-contents .title {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 40px;
    /* text-transform: uppercase; */
}
.home-main-background-img-contents .main-text-div{
    margin-bottom: 50px;
    height: 128px;
    font-size: 54px;
    font-weight: 700;
    width: 100%;
    position: relative;
}
.home-main-background-img-contents .main-text{
    font-size: 54px;
    font-weight: 700;
    width: 70%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
}
.home-main-background-img-contents .main-text.long {
    width: 100%;
}

.home-story{
    padding: 80px 10% ;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    width: 100%;
}
.home-story .my-column{
    width: 44%;


}
.home-story .my-column .my-contents .text-wrapper{
    color: #182135;
}
.home-story .my-column .my-contents .text-wrapper h3 {
    font-weight: 600;
    color: #FFB000;
    margin-bottom: 45px;

}
.home-story .my-column .my-contents .text-wrapper p{
    margin-bottom: 30px;
}
.home-story .my-column .my-contents .text-wrapper p.first{
    font-weight: 500;
}
.home-story .my-column .my-contents .link-wrapper{
    margin-top: 50px;
}
.home-story .my-column .my-contents .link-wrapper a{
    background-color: #FFB000;
   
    color: #fff;
    padding: 12px;
    border-radius: 5px;
    font-weight: 600;
    transition: 100ms ease-in-out;
    display: inline-block;
}
.home-story .my-column .my-contents .link-wrapper a:hover{
    background-color: #d69200;
    transition: 100ms ease-in-out;
    text-decoration: none;
}
.home-story .my-column .img-wrapper {
    padding: 240px 60px;
    width: 100%;
    height: 100%;
    max-height: 500px;
    margin-left: auto;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 5px;
    position: relative;
    /* box-shadow:0px 0px 8px 3px rgb(240 118 29 / 12%); */
}

.home-story .my-column .img-wrapper .mission{
    position: absolute;
    background-color: #FFB000;
    color: #fff;
    padding: 15px;
    width: 60%;
    border-radius: 5px;
    top: 40%;
    right: 50%;
}
.home-story .my-column .img-wrapper .mission .my-icon{
    font-size: 32px;
}
.home-story .my-column .img-wrapper .mission h5{
    font-weight: 600;
    margin: 16px 0 10px;
}
.our-services-home{
    background-color: #fffaf6;
    padding: 80px 10% ;
}

.our-services-home .main-title {
    font-weight: 600;
    margin-bottom: 15px;
    color: #FFB000;
}

.our-services-home .services-grid-container{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 28px;
    margin-top: 60px;
}
.our-services-home .services-grid-container .my-card{
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    
    box-shadow: 1px 1px 6px 5px rgb(148 148 148 / 7%);
    text-align: center;
   
}
.our-services-home .services-grid-container .my-card .my-icon-wrapper{
    height: 50px;
}
.our-services-home .services-grid-container .my-card .my-icon-wrapper img{
    height:100%
}
.our-services-home .services-grid-container .my-card .title{
    font-size: 18px;
    font-weight: 600;
    margin: 30px 0 20px;
}
.our-services-home .services-grid-container .my-card .link-wrapper {
    margin-top: 40px;
}
.our-services-home .services-grid-container .my-card .link-wrapper a{
    background-color: #FFB000;
   
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    font-weight: 500;
    transition: 100ms ease-in-out;
    display: inline-block;
}
.our-services-home .services-grid-container .my-card .link-wrapper a:hover{
    background-color: #d69200;
    transition: 100ms ease-in-out;
    text-decoration: none;
}

.our-services-home .services-grid-container .my-card .desc {
    min-height: 160px;
}

@media screen and (max-width: 1024px) {
    .background-img-wrapper{
        padding: 520px 7% 320px;
    }
    .backgroundTextBackground {
        padding: 0 7%;
    }
    .home-main-background-img-contents .main-text{
        width: 80%;
    }
    .home-story {
        padding: 80px 7%;
        overflow: hidden;
    }
    .our-services-home {
        padding: 80px 7%;
        overflow: hidden;
    }
    .home-story .my-column .my-contents .text-wrapper{
        color: #182135;
        overflow: hidden;
    }
}
@media screen and (max-width: 991px) {
    .background-img-wrapper{
        padding: 520px 7% 320px;
    }
    .home-main-background-img-contents .main-text-div{
        width: 100%;
    }
    .home-main-background-img-contents .main-text{
        width: 85%;
        font-size: 52px;
    }

    .our-services-home .services-grid-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
}
@media screen and (max-width: 768px) {
    .background-img-wrapper{
        padding: 270px 7%;
    }
    .home-main-background-img-contents .main-text-div {
        width: unset;
        margin-bottom: 20px;
    }
    .home-main-background-img-contents .title {
        font-size: 16px;
        margin-bottom: 25px;
    }
    .home-main-background-img-contents .main-text{
        font-size: 42px;
    }
    .home-main-background-img-contents .tagline {
        font-size: 16px;
    }
    .home-story{
        flex-direction: column;
        padding: 50px 7%;
    }
    .home-story .my-column {
        width: 100%;
        margin-bottom: 50px;
    }
    .home-story .my-column .my-contents .text-wrapper h3 {
        font-size: 24px;
        margin-bottom: 25px;
    }
    .home-story .my-column .my-contents .text-wrapper p {
        margin-bottom: 15px;
    }
    .home-story .my-column .my-contents .link-wrapper {
        margin-top: 30px;
    }
    .home-story .my-column .img-wrapper .mission{
        width: 40%;
        top: 40%;
        right: 55%;
    }
}
@media screen and (max-width: 425px) {

    .home-main-background-img-contents .main-text {
        font-size: 28px;
        width: 100%;
        
    }
.home-main-background-img-contents .main-text-div {
    width: 100%;
    height: 100px;
}
.home-main-background-img-contents .title {
    margin-bottom: 20px;
    font-weight: 600;
}
.background-img-wrapper {
    padding: 250px 15px;
}
.backgroundTextBackground {
    padding: 0 15px;
}
.home-main-background-img-contents .tagline {
    font-size: 15px;
    font-weight: 500;
}
.home-story {
    padding: 50px 15px;
}
.home-story .my-column .my-contents .text-wrapper h3 {
    font-size: 21px;
}
.home-story .my-column .my-contents .text-wrapper p.first {
    font-weight: unset;
}
.home-story .my-column .my-contents .text-wrapper p{
    font-size: 15px;
}
.home-story .my-column .my-contents .link-wrapper {
    margin-top: 25px;
}
.home-story .my-column .my-contents .link-wrapper a{
    padding: 10px;
    font-weight: 500;
}
.home-story .my-column .img-wrapper .mission {
    width: 60%;
    top: 50%;
    right: 30%;
}
.home-story .my-column .img-wrapper .mission h5{
    font-size: 18px;
    margin: 10px 0 7px;
}
.home-story .my-column .img-wrapper .mission p{
    font-size: 15px;
}
.our-services-home {
    padding: 50px 15px;
}
.our-services-home .main-title{
    font-size: 21px;
}
.our-services-home .main-desc{
    font-size: 15px;
}
.our-services-home .services-grid-container {
    grid-template-columns: 1fr;
}
.our-services-home .services-grid-container .my-card .title {
    font-size: 16px;
    font-weight: 600;
    margin: 20px 0 10px;
}
.our-services-home .services-grid-container .my-card .desc {
    font-size: 15px;
}
.our-services-home .services-grid-container .my-card .link-wrapper {
    margin-top: 20px;
}
.our-services-home .services-grid-container .my-card .link-wrapper a{
    font-size: 15px;
}
}

@media screen and (max-width: 375px){
.home-story .my-column .img-wrapper .mission {
    width: 65%;
}}