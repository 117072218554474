.about-page-container{

}
.about-page-header{
    padding: 220px 10% 200px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}
.about-page-header .my-text-bg {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: #0000004f;
display: flex;
align-items: center;
justify-content: center;
}
.about-page-header h1 {
    text-align: center;
    color: #ffff;
    font-weight:700;
}
.our-history-wrapper {
    padding: 100px 10% 80px;
    background-color: #fffaf6;
}
.our-history-wrapper .main-title-div {
    /* text-align: center; */
}
.our-history-wrapper .main-title-div h2{
    color: #FFB000;
    font-weight: 600;
    margin-bottom: 45px;
}
.our-history-wrapper .main-title-div p{
    /* max-width: 60%; */
    margin: auto;
    text-align: left;
}
.milestone-wrapper{
    margin: 30px 0 20px;
    padding: 1px 0;
}
.milestone-wrapper .date-title{
    text-align: center;
    color: #1d123a;
    margin: 80px 0 50px;
    font-weight: 400;
}
.milestone-wrapper .date-title span {
    color: #FFB000;
    font-weight: 600;
}
.milestone-flex{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.milestone-flex.my-left {
    flex-direction: row-reverse;
}
.milestone-flex .my-text-wrapper{
    width: 42%;
}
.milestone-flex .my-text-wrapper h4{
    margin-bottom: 20px;
    font-weight: 600;
}
.milestone-flex  .my-img-wrapper{
    width: 42%;
    border-radius: 60% 43% 25% 32% / 76% 58% 55% 30%;
    overflow: hidden;
    box-shadow:3px 1px 10px 3px rgb(240 118 29 / 42%);
}

.milestone-flex  .my-img-wrapper.my-left{
    /* -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
     */
     border-radius:  43% 60%  32% 25%  / 58% 76% 30% 55%;
}
.milestone-flex  .my-img-wrapper img{
    width: 100%;
}

.our-team-wrapper{
    padding: 100px 10% 80px;;
    background-color: #fffaf6;
}
.our-team-wrapper .main-text-wrapper {
    margin-bottom: 50px;
}
.our-team-wrapper .main-text-wrapper h3{
    font-weight: 600;
    color: #FFB000;
}
.my-team-flex {
    display: flex;
    justify-content: space-between;
    box-shadow: 0px 1px 15px rgb(0 0 0 / 12%);
    border-radius: 10px;
    width: 100%;
    overflow: hidden;
    margin-bottom: 50px;
    background-color: #ffff;
}
.my-team-flex.my-right{
    flex-direction: row-reverse;
}
.my-team-flex.my-right .my-img-wrapper{
    border: none;
    border-left: 6px solid #FFB000;

}
.my-team-flex .my-img-wrapper{
    width: 25%;
    border-right: 6px solid #FFB000;
    box-sizing: border-box;
}
.my-team-flex .my-img-wrapper img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  
}
.my-team-flex .my-text-wrapper{
    width: 75%;
    padding: 25px;
    min-height: 458px;
    box-sizing: border-box;
}
.my-team-flex .my-text-wrapper h4{
    margin-bottom: 20px;
    font-weight: 600;
}
.my-team-flex .my-text-wrapper h4 span  {
    color: #FFB000;
    font-weight: 400;
}


.home-story.no-padding {
    display: block;
    padding: 0;
    background: none;
}
.home-story.no-padding .my-column {
    width: 100%;
}

@media screen and (max-width: 1024px) {
    .about-page-header {
        padding: 170px 7% 160px;
    }
    .our-history-wrapper{
        padding: 80px 7%;
        overflow-x: hidden;
    }
    .our-team-wrapper{
        padding: 80px 7%;
        overflow-x: hidden;
    }
    .my-team-flex .my-text-wrapper{
        width: 65%;
    }
.my-team-flex .my-img-wrapper{
    width: 35%;
}
.my-team-flex .my-text-wrapper h4 {
    font-size: 21px;
}
    
}
@media screen and (max-width: 991px) {
    .our-history-wrapper .main-title-div h2{
        font-size: 28px;
    }
    .milestone-wrapper .date-title{
        font-size: 24px;
        margin: 70px 0 40px;
    }
    .milestone-flex .my-text-wrapper h4{
        font-size: 18px;
        margin-bottom: 10px;
    }
    .milestone-flex .my-text-wrapper, .milestone-flex .my-img-wrapper {
        width: 46%;
    }
}

@media screen and (max-width: 768px) {
    .about-page-header h1 {
        font-size: 36px;
    }
    .milestone-flex {
        display: block;
    }
    .milestone-flex .my-text-wrapper, .milestone-flex .my-img-wrapper {
        width: 100%;
    }
    .our-history-wrapper .main-title-div h2{
        margin-bottom: 35px;
    }
    .milestone-wrapper {
        margin: 30px 0 20px;
    }
    .milestone-flex .my-text-wrapper{
        margin-bottom: 30px;
    }
    .milestone-flex .my-img-wrapper{
        width: 80%;
        margin: auto;
    }
    .my-team-flex{
        display: block;
    }
    .my-team-flex .my-img-wrapper, 
    .my-team-flex .my-text-wrapper{
        width: 100%;
    }
    .my-team-flex .my-img-wrapper, .my-team-flex.my-right .my-img-wrapper{
        border: none;
        border-bottom: 6px solid #FFB000;
        height: 400px;
    }
    .our-team-wrapper .main-text-wrapper h3 {
        font-size: 24px;
        margin-bottom: 25px;
    }
    .our-team-wrapper {
        padding: 50px 7%;
    }
    .my-team-flex .my-text-wrapper{
        min-height: unset;
    }
}
@media screen and (max-width: 425px){
    .about-page-header {
        padding: 170px 15px 120px;
    }
.about-page-header h1 {
    font-size: 28px;
}
.our-history-wrapper {
    padding: 50px 15px;
}
.milestone-flex .my-img-wrapper {
    width: 100%;
}
.milestone-wrapper .date-title {
    margin: 40px 0 20px;
    font-size: 21px;
}
.our-history-wrapper .main-title-div p{
    font-size: 15px;
}
.our-history-wrapper .main-title-div h2{
    font-size: 24px;
    margin-bottom: 20px;
}
.milestone-flex .my-text-wrapper p {
    font-size: 15px;
}
.our-team-wrapper .main-text-wrapper h3 {
    font-size: 21px;
}
.our-team-wrapper {
    padding: 50px 15px;
}
.my-team-flex .my-text-wrapper{
    font-size: 15px;
    padding: 20px 12px;
}
.my-team-flex .my-text-wrapper h4{
    font-size: 18px;
}
.my-team-flex .my-text-wrapper h4 span{
    display: block;
}
.my-team-flex .my-img-wrapper, .my-team-flex.my-right .my-img-wrapper{
    height: 300px;
}
}


/* Mission Vission */

.our-mission-container{
    padding: 100px 10% 80px;
    background-color: #fffaf6;
}
.our-mission-container .main-title-div h2{
    color: #FFB000;
    font-weight: 600;
}
.our-mission-container .main-title-div{
    margin-bottom: 70px;
}
.my-mission-wrapper {
    display: flex;
    width: 100%;
}
.my-mission-wrapper .my-img-wrapper, .my-mission-wrapper .my-text-wrapper {
    width: 50%;
    min-height: 400px;
}
.my-mission-wrapper .my-img-wrapper img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.my-mission-wrapper .my-text-wrapper{
    padding: 60px 40px 40px;
    box-sizing: border-box;
    background-color: #ffff;
}
.my-text-wrapper.my-colored-Bg {
    background: #fffaf6;
}
.my-mission-wrapper .my-text-wrapper h3 {
    color: #1d123a;
    margin-bottom: 30px;
    font-weight: 600;
    color: #FFB000;
}
.my-mission-wrapper .my-text-wrapper ul {
    list-style-type: disc;
}
.my-mission-wrapper .my-text-wrapper li{
    margin-bottom: 10px;
}
.my-mission-wrapper .my-text-wrapper li span {
    font-weight: 600;
}
.my-mission-wrapper.vision{
    flex-direction: row-reverse;
}
.mission-div-box-shadow {
    box-shadow:0px 0px 8px 3px rgb(240 118 29 / 12%);
    border-radius: 10px;
    overflow: hidden;
    /* margin-top: 30px; */
}
@media screen and (max-width: 1024px) {
    .our-mission-container{
        padding: 80px 7%;
        overflow-x: hidden;
    }
}
@media screen and (max-width: 991px) {
    .our-mission-container .main-title-div h2{
        font-size: 28px;
    }
    .my-mission-wrapper .my-text-wrapper h3{
        font-size: 24px;
        margin-bottom: 20px;
    }
    .my-mission-wrapper .my-text-wrapper li{
        margin-bottom: 2px;
    }
}
@media screen and (max-width: 768px) {
    .our-mission-container .main-title-div {
        margin-bottom: 50px;
    }
    .my-mission-wrapper{
        display: block;
        box-shadow:0px 0px 5px 2px rgb(240 118 29 / 12%);
        border-radius: 7px;
        overflow: hidden;
        margin-bottom: 40px;
    }
    .my-mission-wrapper .my-img-wrapper, .my-mission-wrapper .my-text-wrapper{
        width: 100%;
        min-height: unset;
        
    }
    .my-mission-wrapper .my-text-wrapper {
        padding: 40px 20px;
    }
    .my-mission-wrapper .my-img-wrapper{
        height: 300px;
    }
    .mission-div-box-shadow {
        box-shadow: none;
        border-radius: 0;
        overflow: visible;
    }
    .my-mission-wrapper .my-text-wrapper ul {
        list-style-type: none;
    }
}
@media screen and (max-width: 425px) {
    .our-mission-container{
        padding: 50px 15px;
    }
    .our-mission-container .main-title-div {
        margin-bottom: 30px;
    }
    .our-mission-container .main-title-div h2{
        font-size: 24px;
    }
    .my-mission-wrapper .my-img-wrapper {
        height: 250px;
    }
    .my-mission-wrapper .my-text-wrapper {
        padding: 25px 10px;
    }
    .my-mission-wrapper .my-text-wrapper h3 {
        font-size: 21px;
        margin-bottom: 12px;
    }
    .my-mission-wrapper .my-text-wrapper p , .my-mission-wrapper .my-text-wrapper li{
        font-size: 15px;
    }
}