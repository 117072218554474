/* comment */


nav.my-header{
padding: 30px 10%;
background-color: transparent;
transition: 200ms ease-in-out;

}

nav.scrolled{
    background-color: #fff;
    transition: 200ms ease-in-out;
    box-shadow: 0 2px 5px 1px rgb(64 60 67 / 7%);
}


.navbar-light .navbar-brand{
    color: #fff !important;
    margin-right: 0;
}
.scrolled .navbar-brand{
    color: unset !important;
}

.scrolled .my-nav-links, .scrolled .my-nav-links > a{

    color: #615a7a !important;
    transition: 150ms ease-in-out;
}
.scrolled .my-nav-links:hover, .scrolled .my-nav-links > a:hover{
    color: #1d123a !important;
    transition: 150ms ease-in-out;
}
.my-nav-links, .my-nav-links > a{
    font-weight: 600 ;
    color: #fff !important;
    transition: 150ms ease-in-out;
}

 .my-nav-links:hover, .my-nav-links > a:hover{
    color: #e0e0e0 !important;
    transition: 150ms ease-in-out;
}
.navbar-brand img {
    width: 60%;
    height: auto;
}
@media screen and (max-width: 1024px) {
    nav.my-header{
padding: 25px 7%;

    }
    .navbar-brand {
        width: 30%;
    }
    .navbar-brand img {
        width: 65%;
    }
}

@media screen and (max-width: 991px) {
    .navbar-light .navbar-toggler-icon {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
    }
    .my-nav-links, .my-nav-links > a{ 
        font-weight: 500
;
    }
    .navbar-light .navbar-toggler{
        border-color: transparent !important;
        padding: 0;
    }
    .scrolled .navbar-toggler-icon{
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 176, 0, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
    }
    .my-nav-links, .scrolled .my-nav-links > a{
        margin-bottom: 10px;
    }
    .navbar-collapse {
        margin-top: 10px;
    }
    .navbar-brand {
        width: unset;
    }
}

@media screen and (max-width: 768px) {
    .navbar-brand img {
        width: 55%;
    }
    .home-main-background-img-contents {
        margin-top: 60px;
    }
}
@media screen and (max-width: 425px) {
    .my-nav-links, .scrolled .my-nav-links > a{
        margin-bottom: 0px;
    }
    nav.my-header {
        padding: 25px 15px;
    }
    .navbar-brand{
        width: 80%;
    }
    .navbar-brand img {
        width: 50%;
    }
}


@media all and (min-width: 992px) {
	.navbar .nav-item .dropdown-menu{ display: none; }
	.navbar .nav-item:hover .nav-link{   }
	.navbar .nav-item:hover .dropdown-menu{ display: block; }
	.navbar .nav-item .dropdown-menu{ margin-top:0; }

}

.dropdown-toggle.nav-link{
    display: flex;
    align-items: center;
}