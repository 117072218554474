.what-we-do-header{
    padding: 220px 10% 200px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}
.what-we-do-header.trading {
    background-position-y: -222px;
}
.what-we-do-header .my-text-bg {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: #0000004f;
display: flex;
align-items: center;
justify-content: center;
}
.what-we-do-header h1 {
    text-align: center;
    color: #ffff;
    font-weight:700;
}

.my-colored-Bg{
    background-color: #fffaf6;
}
.my-swapped {
    flex-direction: row-reverse;
}
.home-story .my-column .my-contents .text-wrapper p.my-p-list{
    margin-bottom: 5px;
}
.home-story .my-column .my-contents .text-wrapper p.my-p-list .link {
    color: #FFB000;
    font-weight: 500;
    text-decoration: underline;
}
.home-story .my-column .my-contents .text-wrapper p.my-p-list .link:hover {
    color: #d69301;
}
.home-story .my-column .my-contents .text-wrapper ul li {
    list-style: disc;
    margin-bottom: 5px;
}
@media screen and (max-width: 1024px) {
    .what-we-do-header.trading {
        background-position-y: center;
    }
}

@media screen and (max-width: 768px){
    .my-swapped {
        flex-direction: column;
    }
}