.home-story .my-column.contact-form {
    width: 60%;

}
.home-story .my-column.contact-form h3, .home-story .my-column.contact-details-wrapper h3 {
    font-weight: 600;
    color: #FFB000;
    margin-bottom: 45px;
}
form .file{
    padding: 0;
    border: none;
    border-radius: 0;
}
form input, form textarea {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid rgb(229, 230, 233);
    border-radius: 5px;
    margin-bottom: 20px;
    font-size: 16px;
    padding: 15px;
}
form button {
    background-color: #FFB000;
    color: #fff;
    padding: 12px 20px;
    border-radius: 5px;
    font-weight: 600;
    transition: 100ms ease-in-out;
    display: inline-block;
}
form button:hover {
    background-color: #d69200;
    transition: 100ms ease-in-out;
    text-decoration: none;
}
.home-story .my-column.contact-details-wrapper {
    width: 28%;
}

.my-column.contact-details-wrapper .footerLinks{
    color: inherit;
    font-size: 16px;
}
.my-column.contact-details-wrapper .footerLinks.not-links .icon{
    font-size: 28px;
}
.my-column.contact-details-wrapper .footerLinks.not-links{
    align-items: flex-start;
}
button:disabled {
    background: #dddddd;
  }
  button:disabled:hover {
    background: #dddddd;
  }
  .contact-form-success{
      color: #11bb44;
      border: 1px solid #11bb44 ;
      display: inline-block;
      padding: 5px;
      margin-bottom: 25px;
  }
  .contact-form-error {
    color: #bb1111;
    border: 1px solid #bb1111 ;
    display: inline-block;
    padding: 5px;
    margin-bottom: 25px;
  }

  @media screen and (max-width: 991px) {
    .home-story .my-column.contact-details-wrapper {
        width: 33%;
    }
  }

@media screen and (max-width: 768px) {
.home-story .my-column.contact-form h3, .home-story .my-column.contact-details-wrapper h3 {
        margin-bottom: 25px;
        font-size: 24px;
    }
    .home-story .my-column.contact-form {
        width: 100%;
    }
    .home-story .my-column.contact-details-wrapper {
        width: 100%;
    }

form input, form textarea {
    padding: 10px;
}
}


@media screen and (max-width: 425px){
    .home-story .my-column.contact-form h3, .home-story .my-column.contact-details-wrapper h3 {
    font-size: 21px;
}
    form button{
        padding: 10px 20px;
        font-weight: 500;
    }
}