/* Comment  */

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Noto+Sans:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

body {
  margin: 0;
  /* font-family: 'Noto Sans', sans-serif; */
  /* font-family: 'Heebo', sans-serif; */
  /* font-family: 'Poppins', sans-serif; */
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



input,
textarea {
	transition: 200ms ease-in-out;
}
input:focus,
textarea:focus {
	outline: none;
}
input:focus-visible,
textarea:focus-visible {
	outline: none;
	border: 1px solid #cbb8ff !important;
	transition: 100ms ease;
}
button,
input {
	padding: 0;
	font-family: "DM Sans", sans-serif;
	box-sizing: border-box;
	border: none;
}
button {
	cursor: pointer;
	font-size: inherit;
}
ul {
	list-style: none;
	margin: 0;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
hr {
	margin: 0;
	padding: 0;
}
hr {
	border-color: rgba(160, 177, 196, 0.1);
}
a {
	color: inherit;
	text-decoration: none;
}