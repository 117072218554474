/* Commenting to fix the production bug */


@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Noto+Sans:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

body {
  margin: 0;
  /* font-family: 'Noto Sans', sans-serif; */
  /* font-family: 'Heebo', sans-serif; */
  font-family: 'Poppins', sans-serif;
  /* font-family: 'Montserrat', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #121a3a;

 
}
html{overflow-x: hidden;}
input,
textarea {
	transition: 200ms ease-in-out;
}
input:focus,
textarea:focus {
	outline: none;
}
input:focus-visible,
textarea:focus-visible {
	outline: none;
	border: 1px solid #1d123a71 !important;
	transition: 100ms ease;
}
button,
input {
	padding: 0;
	font-family: inherit;
	box-sizing: border-box;
	border: none;
}
button {
	cursor: pointer;
	font-size: inherit;
}
ul {
	list-style: none;
	margin: 0;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
hr {
	margin: 0;
	padding: 0;
}
hr {
	border-color: rgba(160, 177, 196, 0.1);
}
a {
	color: inherit;
	text-decoration: none;
    transition: 100ms ease-in-out;
}
p{

}



.footer {
	padding: 60px 0 60px;
	background-color: #182135;
}
.footerWrapper {
	width: 100%;
    padding: 0 10%;
	margin-right: auto;
	margin-left: auto;
}
.footerGrid {
	width: 100%;
	display: -ms-grid;
	display: grid;
	-ms-grid-rows: auto;
	grid-template-rows: auto;
	-ms-grid-columns: 1.5fr 1fr 1fr 1fr;
	grid-template-columns: 1.5fr 1fr 1fr 1fr;
	grid-column-gap: 50px;
}
.footerLegalBlock {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
    margin-top: 20px;
    padding-top: 20px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-top: 1px solid hsla(0, 0%, 100%, 0.18);
	color: #fff;
	font-size: 15px;
}
.footerGrid .column {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-align: start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}
.footerGrid .column.center {
	/* justify-content: center; */
}
.footerGrid .column .title {
	color: #fff;
	height: 55px;
	font-weight: 700;
    font-size: 18px;
    margin-bottom: 0 !important;
}
.footerWrapper .logo-title{
    height: 55px;
	margin-bottom: 40px;
}
.footerWrapper .logo-title img{
	height: 100%;
}
.footerGrid .column .about {
    color: #d4d4d4;
    font-size: 15px;
    
}
.footerLinks {
	padding: 2px 0;
	color: #d4d4d4;
	font-size: 15px;
	text-decoration: none;
}
.footerLinks.first{
    padding-top: 0;
}
.footerLinks.first.not-links{
    /* align-items: unset; */
}

.footerLinks.not-links{
    display: flex;
    margin-bottom: 15px;
    align-items: center;
}
.footerLinks.not-links .icon{
    margin-right: 20px;
    color: #FFB000;
    font-size: 22px;

}
.footerLinks a:hover{
    text-decoration: none;
    transition: 100ms ease-in-out;
    color: #fff;
}
.footerText {
	margin-top: 4px;
	margin-bottom: 20px;
	padding-top: 8px;
	padding-right: 6px;
	padding-bottom: 8px;
	color: #fff;
	font-size: 16px;
	text-decoration: none;
}


.footerLegalBlock .text {
	color: #d4d4d4;
	font-size: 15px;
}
.footerLegalBlock .socialLinks {
	display: flex;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: -webkit-box;
	-webkit-box-align: center;
	align-items: center;
}

@media screen and (max-width: 1024px) {
    .footer{
        padding-top: 60px;
    }
    .footerWrapper{
        padding: 0 7%;
    }
    .footerGrid{
        grid-column-gap: 50px;
        grid-template-columns: 1.5fr 1fr 1fr;
    }
    .footerLinks{
        font-size: 15px;
    }
    .footerLinks.not-links .icon {
        margin-right: 13px;
    }
    .footerLegalBlock .text {
        font-size: 15px;
    }
}

@media screen and (max-width: 991px) {
    .footer{
        padding: 50px 0;
    }
  .footerWrapper .logo-title {
       height: 45px;
    }
    .footerGrid .column .about {
        font-size: 14px;
    }
    .footerGrid .column .title{
        font-size: 16px;
    }
    .footerLinks {
        font-size: 14px;
    }
    
}

@media screen and (max-width: 768px) {
	.footerGrid {
		grid-template-columns: 1fr;
	}
   .footerGrid .column .title {
        height: 40px;
    }
    .footerGrid .column{
        margin-bottom: 40px;
    }
    .footerLinks.not-links {
        display: flex;
        margin-bottom: 10px;
    }
	.footerGrid .column .logo-title img {
		/* height: 100%; */
	}
}

@media screen and (max-width: 425px) {
	.footer {
		padding: 20px 5%;
	}
    .footerWrapper{
        padding-left: 0;
        padding-right: 0;
    }
	.footerWrapper .logo-title {
		height: 42px;
	 }
	.footerGrid .column .title {
		margin-bottom: 10px;
	}
	.footerGrid .column {
		margin-bottom: 40px;
	}
	.footerLinks {
		margin-bottom: 0;
		padding-right: 0;
		font-size: 14px;
	}
	.footerGrid {
		grid-template-columns: 1fr;
	}
	.footerLegalBlock {
		margin-top: 0;
		padding-top: 22px;
	}
	.footerLegalBlock .text {
        font-size: 12px;
    }
    .footerLinks.not-links .icon {
        font-size: 21px;
    }
	.footerGrid .column .logo-title img {
		height: 70%;
	}
}
@media screen and (max-width: 375px) {
	.footer {
		padding: 20px 10px;
	}
}

.dropdown-menu {
	left: -50%;
}

.dropdown-item {
    color: #FFB000;
	font-weight: 500;
}