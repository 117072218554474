@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Noto+Sans:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Heebo:wght@300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&family=Poppins:wght@300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&family=Noto+Sans:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Heebo:wght@300;400;500;600;700;800;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&family=Poppins:wght@300;400;500;600;700;800;900&display=swap);
/* Comment  */

body {
  margin: 0;
  /* font-family: 'Noto Sans', sans-serif; */
  /* font-family: 'Heebo', sans-serif; */
  /* font-family: 'Poppins', sans-serif; */
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



input,
textarea {
	transition: 200ms ease-in-out;
}
input:focus,
textarea:focus {
	outline: none;
}
input:focus-visible,
textarea:focus-visible {
	outline: none;
	border: 1px solid #cbb8ff !important;
	transition: 100ms ease;
}
button,
input {
	padding: 0;
	font-family: "DM Sans", sans-serif;
	box-sizing: border-box;
	border: none;
}
button {
	cursor: pointer;
	font-size: inherit;
}
ul {
	list-style: none;
	margin: 0;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
hr {
	margin: 0;
	padding: 0;
}
hr {
	border-color: rgba(160, 177, 196, 0.1);
}
a {
	color: inherit;
	text-decoration: none;
}
/* Comment */


@-webkit-keyframes slider-animation {
    
    0% {
        opacity: 0;
    }
    6% {
        opacity: 1;
    }
    24% {
        opacity: 1;
    }
    30% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}


@keyframes slider-animation {
    
    0% {
        opacity: 0;
    }
    6% {
        opacity: 1;
    }
    24% {
        opacity: 1;
    }
    30% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

.background-img-wrapper{
    padding: 450px 10% 415px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;
}
.backgroundImageWrapper2, .backgroundImageWrapper3, .backgroundImageWrapper4 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    transition: ease-in 150ms;
}

/* Animations */
.backgroundImageWrapper4, .backgroundImageWrapper2, backgroundImageWrapper3 {
    opacity: 0;
}
.backgroundImageWrapper2.hide, .backgroundImageWrapper3.hide, .backgroundImageWrapper4.hide {
    opacity: 0;
    transition: ease-out 650ms;
}

.backgroundImageWrapper2.show, .backgroundImageWrapper3.show, .backgroundImageWrapper4.show {
    opacity: 1;
    transition: ease-in 400ms;
}
.tagline-div {
    width: 100%;
    height: 21px;
    position: relative;
}

.tagline.slide.show, .home-main-background-img-contents .main-text.show {
    opacity: 1;
    transition: all ease-in 500ms;
    transition-delay: 700ms;
}
.tagline.slide.hide, .home-main-background-img-contents .main-text.hide {
    opacity: 0;
    transition: ease-out 450ms;

}
.home-main-background-img-contents .main-text.show {
    opacity: 1;
    transition: all ease-in 450ms;
    transition-delay: 500ms;
}
 .home-main-background-img-contents .main-text.hide {
    opacity: 0;
    transition: ease-out 400ms;

}
.tagline.slide {
    opacity: 0;
}
.home-main-background-img-contents .tagline{
    font-size: 18px;
    font-weight: 600;
    position: absolute;
    top: 0;
    left: 0;
}
.backgroundTextBackground {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #18213567;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding: 0 10%;
}
.home-main-background-img-contents {
    width: 100%;
    color: #fff;
}
.home-main-background-img-contents .title {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 40px;
    /* text-transform: uppercase; */
}
.home-main-background-img-contents .main-text-div{
    margin-bottom: 50px;
    height: 128px;
    font-size: 54px;
    font-weight: 700;
    width: 100%;
    position: relative;
}
.home-main-background-img-contents .main-text{
    font-size: 54px;
    font-weight: 700;
    width: 70%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
}
.home-main-background-img-contents .main-text.long {
    width: 100%;
}

.home-story{
    padding: 80px 10% ;
    background-color: #fff;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    width: 100%;
}
.home-story .my-column{
    width: 44%;


}
.home-story .my-column .my-contents .text-wrapper{
    color: #182135;
}
.home-story .my-column .my-contents .text-wrapper h3 {
    font-weight: 600;
    color: #FFB000;
    margin-bottom: 45px;

}
.home-story .my-column .my-contents .text-wrapper p{
    margin-bottom: 30px;
}
.home-story .my-column .my-contents .text-wrapper p.first{
    font-weight: 500;
}
.home-story .my-column .my-contents .link-wrapper{
    margin-top: 50px;
}
.home-story .my-column .my-contents .link-wrapper a{
    background-color: #FFB000;
   
    color: #fff;
    padding: 12px;
    border-radius: 5px;
    font-weight: 600;
    transition: 100ms ease-in-out;
    display: inline-block;
}
.home-story .my-column .my-contents .link-wrapper a:hover{
    background-color: #d69200;
    transition: 100ms ease-in-out;
    text-decoration: none;
}
.home-story .my-column .img-wrapper {
    padding: 240px 60px;
    width: 100%;
    height: 100%;
    max-height: 500px;
    margin-left: auto;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 5px;
    position: relative;
    /* box-shadow:0px 0px 8px 3px rgb(240 118 29 / 12%); */
}

.home-story .my-column .img-wrapper .mission{
    position: absolute;
    background-color: #FFB000;
    color: #fff;
    padding: 15px;
    width: 60%;
    border-radius: 5px;
    top: 40%;
    right: 50%;
}
.home-story .my-column .img-wrapper .mission .my-icon{
    font-size: 32px;
}
.home-story .my-column .img-wrapper .mission h5{
    font-weight: 600;
    margin: 16px 0 10px;
}
.our-services-home{
    background-color: #fffaf6;
    padding: 80px 10% ;
}

.our-services-home .main-title {
    font-weight: 600;
    margin-bottom: 15px;
    color: #FFB000;
}

.our-services-home .services-grid-container{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 28px;
    gap: 28px;
    margin-top: 60px;
}
.our-services-home .services-grid-container .my-card{
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    
    box-shadow: 1px 1px 6px 5px rgb(148 148 148 / 7%);
    text-align: center;
   
}
.our-services-home .services-grid-container .my-card .my-icon-wrapper{
    height: 50px;
}
.our-services-home .services-grid-container .my-card .my-icon-wrapper img{
    height:100%
}
.our-services-home .services-grid-container .my-card .title{
    font-size: 18px;
    font-weight: 600;
    margin: 30px 0 20px;
}
.our-services-home .services-grid-container .my-card .link-wrapper {
    margin-top: 40px;
}
.our-services-home .services-grid-container .my-card .link-wrapper a{
    background-color: #FFB000;
   
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    font-weight: 500;
    transition: 100ms ease-in-out;
    display: inline-block;
}
.our-services-home .services-grid-container .my-card .link-wrapper a:hover{
    background-color: #d69200;
    transition: 100ms ease-in-out;
    text-decoration: none;
}

.our-services-home .services-grid-container .my-card .desc {
    min-height: 160px;
}

@media screen and (max-width: 1024px) {
    .background-img-wrapper{
        padding: 520px 7% 320px;
    }
    .backgroundTextBackground {
        padding: 0 7%;
    }
    .home-main-background-img-contents .main-text{
        width: 80%;
    }
    .home-story {
        padding: 80px 7%;
        overflow: hidden;
    }
    .our-services-home {
        padding: 80px 7%;
        overflow: hidden;
    }
    .home-story .my-column .my-contents .text-wrapper{
        color: #182135;
        overflow: hidden;
    }
}
@media screen and (max-width: 991px) {
    .background-img-wrapper{
        padding: 520px 7% 320px;
    }
    .home-main-background-img-contents .main-text-div{
        width: 100%;
    }
    .home-main-background-img-contents .main-text{
        width: 85%;
        font-size: 52px;
    }

    .our-services-home .services-grid-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
}
@media screen and (max-width: 768px) {
    .background-img-wrapper{
        padding: 270px 7%;
    }
    .home-main-background-img-contents .main-text-div {
        width: unset;
        margin-bottom: 20px;
    }
    .home-main-background-img-contents .title {
        font-size: 16px;
        margin-bottom: 25px;
    }
    .home-main-background-img-contents .main-text{
        font-size: 42px;
    }
    .home-main-background-img-contents .tagline {
        font-size: 16px;
    }
    .home-story{
        -webkit-flex-direction: column;
                flex-direction: column;
        padding: 50px 7%;
    }
    .home-story .my-column {
        width: 100%;
        margin-bottom: 50px;
    }
    .home-story .my-column .my-contents .text-wrapper h3 {
        font-size: 24px;
        margin-bottom: 25px;
    }
    .home-story .my-column .my-contents .text-wrapper p {
        margin-bottom: 15px;
    }
    .home-story .my-column .my-contents .link-wrapper {
        margin-top: 30px;
    }
    .home-story .my-column .img-wrapper .mission{
        width: 40%;
        top: 40%;
        right: 55%;
    }
}
@media screen and (max-width: 425px) {

    .home-main-background-img-contents .main-text {
        font-size: 28px;
        width: 100%;
        
    }
.home-main-background-img-contents .main-text-div {
    width: 100%;
    height: 100px;
}
.home-main-background-img-contents .title {
    margin-bottom: 20px;
    font-weight: 600;
}
.background-img-wrapper {
    padding: 250px 15px;
}
.backgroundTextBackground {
    padding: 0 15px;
}
.home-main-background-img-contents .tagline {
    font-size: 15px;
    font-weight: 500;
}
.home-story {
    padding: 50px 15px;
}
.home-story .my-column .my-contents .text-wrapper h3 {
    font-size: 21px;
}
.home-story .my-column .my-contents .text-wrapper p.first {
    font-weight: unset;
}
.home-story .my-column .my-contents .text-wrapper p{
    font-size: 15px;
}
.home-story .my-column .my-contents .link-wrapper {
    margin-top: 25px;
}
.home-story .my-column .my-contents .link-wrapper a{
    padding: 10px;
    font-weight: 500;
}
.home-story .my-column .img-wrapper .mission {
    width: 60%;
    top: 50%;
    right: 30%;
}
.home-story .my-column .img-wrapper .mission h5{
    font-size: 18px;
    margin: 10px 0 7px;
}
.home-story .my-column .img-wrapper .mission p{
    font-size: 15px;
}
.our-services-home {
    padding: 50px 15px;
}
.our-services-home .main-title{
    font-size: 21px;
}
.our-services-home .main-desc{
    font-size: 15px;
}
.our-services-home .services-grid-container {
    grid-template-columns: 1fr;
}
.our-services-home .services-grid-container .my-card .title {
    font-size: 16px;
    font-weight: 600;
    margin: 20px 0 10px;
}
.our-services-home .services-grid-container .my-card .desc {
    font-size: 15px;
}
.our-services-home .services-grid-container .my-card .link-wrapper {
    margin-top: 20px;
}
.our-services-home .services-grid-container .my-card .link-wrapper a{
    font-size: 15px;
}
}

@media screen and (max-width: 375px){
.home-story .my-column .img-wrapper .mission {
    width: 65%;
}}
/* comment */


nav.my-header{
padding: 30px 10%;
background-color: transparent;
transition: 200ms ease-in-out;

}

nav.scrolled{
    background-color: #fff;
    transition: 200ms ease-in-out;
    box-shadow: 0 2px 5px 1px rgb(64 60 67 / 7%);
}


.navbar-light .navbar-brand{
    color: #fff !important;
    margin-right: 0;
}
.scrolled .navbar-brand{
    color: unset !important;
}

.scrolled .my-nav-links, .scrolled .my-nav-links > a{

    color: #615a7a !important;
    transition: 150ms ease-in-out;
}
.scrolled .my-nav-links:hover, .scrolled .my-nav-links > a:hover{
    color: #1d123a !important;
    transition: 150ms ease-in-out;
}
.my-nav-links, .my-nav-links > a{
    font-weight: 600 ;
    color: #fff !important;
    transition: 150ms ease-in-out;
}

 .my-nav-links:hover, .my-nav-links > a:hover{
    color: #e0e0e0 !important;
    transition: 150ms ease-in-out;
}
.navbar-brand img {
    width: 60%;
    height: auto;
}
@media screen and (max-width: 1024px) {
    nav.my-header{
padding: 25px 7%;

    }
    .navbar-brand {
        width: 30%;
    }
    .navbar-brand img {
        width: 65%;
    }
}

@media screen and (max-width: 991px) {
    .navbar-light .navbar-toggler-icon {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
    }
    .my-nav-links, .my-nav-links > a{ 
        font-weight: 500
;
    }
    .navbar-light .navbar-toggler{
        border-color: transparent !important;
        padding: 0;
    }
    .scrolled .navbar-toggler-icon{
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 176, 0, 1%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
    }
    .my-nav-links, .scrolled .my-nav-links > a{
        margin-bottom: 10px;
    }
    .navbar-collapse {
        margin-top: 10px;
    }
    .navbar-brand {
        width: unset;
    }
}

@media screen and (max-width: 768px) {
    .navbar-brand img {
        width: 55%;
    }
    .home-main-background-img-contents {
        margin-top: 60px;
    }
}
@media screen and (max-width: 425px) {
    .my-nav-links, .scrolled .my-nav-links > a{
        margin-bottom: 0px;
    }
    nav.my-header {
        padding: 25px 15px;
    }
    .navbar-brand{
        width: 80%;
    }
    .navbar-brand img {
        width: 50%;
    }
}


@media all and (min-width: 992px) {
	.navbar .nav-item .dropdown-menu{ display: none; }
	.navbar .nav-item:hover .nav-link{   }
	.navbar .nav-item:hover .dropdown-menu{ display: block; }
	.navbar .nav-item .dropdown-menu{ margin-top:0; }

}

.dropdown-toggle.nav-link{
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}
/* Commenting to fix the production bug */

body {
  margin: 0;
  /* font-family: 'Noto Sans', sans-serif; */
  /* font-family: 'Heebo', sans-serif; */
  font-family: 'Poppins', sans-serif;
  /* font-family: 'Montserrat', sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #121a3a;

 
}
html{overflow-x: hidden;}
input,
textarea {
	transition: 200ms ease-in-out;
}
input:focus,
textarea:focus {
	outline: none;
}
input:focus-visible,
textarea:focus-visible {
	outline: none;
	border: 1px solid #1d123a71 !important;
	transition: 100ms ease;
}
button,
input {
	padding: 0;
	font-family: inherit;
	box-sizing: border-box;
	border: none;
}
button {
	cursor: pointer;
	font-size: inherit;
}
ul {
	list-style: none;
	margin: 0;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
hr {
	margin: 0;
	padding: 0;
}
hr {
	border-color: rgba(160, 177, 196, 0.1);
}
a {
	color: inherit;
	text-decoration: none;
    transition: 100ms ease-in-out;
}
p{

}



.footer {
	padding: 60px 0 60px;
	background-color: #182135;
}
.footerWrapper {
	width: 100%;
    padding: 0 10%;
	margin-right: auto;
	margin-left: auto;
}
.footerGrid {
	width: 100%;
	display: -ms-grid;
	display: grid;
	-ms-grid-rows: auto;
	grid-template-rows: auto;
	-ms-grid-columns: 1.5fr 1fr 1fr 1fr;
	grid-template-columns: 1.5fr 1fr 1fr 1fr;
	grid-column-gap: 50px;
}
.footerLegalBlock {
	display: -webkit-flex;
	display: flex;
    margin-top: 20px;
    padding-top: 20px;
	-webkit-justify-content: space-between;
	justify-content: space-between;
	-webkit-align-items: center;
	align-items: center;
	border-top: 1px solid hsla(0, 0%, 100%, 0.18);
	color: #fff;
	font-size: 15px;
}
.footerGrid .column {
	display: -webkit-flex;
	display: flex;
	width: 100%;
	-webkit-flex-direction: column;
	flex-direction: column;
	-webkit-align-items: flex-start;
	align-items: flex-start;
}
.footerGrid .column.center {
	/* justify-content: center; */
}
.footerGrid .column .title {
	color: #fff;
	height: 55px;
	font-weight: 700;
    font-size: 18px;
    margin-bottom: 0 !important;
}
.footerWrapper .logo-title{
    height: 55px;
	margin-bottom: 40px;
}
.footerWrapper .logo-title img{
	height: 100%;
}
.footerGrid .column .about {
    color: #d4d4d4;
    font-size: 15px;
    
}
.footerLinks {
	padding: 2px 0;
	color: #d4d4d4;
	font-size: 15px;
	text-decoration: none;
}
.footerLinks.first{
    padding-top: 0;
}
.footerLinks.first.not-links{
    /* align-items: unset; */
}

.footerLinks.not-links{
    display: -webkit-flex;
    display: flex;
    margin-bottom: 15px;
    -webkit-align-items: center;
            align-items: center;
}
.footerLinks.not-links .icon{
    margin-right: 20px;
    color: #FFB000;
    font-size: 22px;

}
.footerLinks a:hover{
    text-decoration: none;
    transition: 100ms ease-in-out;
    color: #fff;
}
.footerText {
	margin-top: 4px;
	margin-bottom: 20px;
	padding-top: 8px;
	padding-right: 6px;
	padding-bottom: 8px;
	color: #fff;
	font-size: 16px;
	text-decoration: none;
}


.footerLegalBlock .text {
	color: #d4d4d4;
	font-size: 15px;
}
.footerLegalBlock .socialLinks {
	display: flex;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: -webkit-box;
	-webkit-align-items: center;
	        align-items: center;
}

@media screen and (max-width: 1024px) {
    .footer{
        padding-top: 60px;
    }
    .footerWrapper{
        padding: 0 7%;
    }
    .footerGrid{
        grid-column-gap: 50px;
        grid-template-columns: 1.5fr 1fr 1fr;
    }
    .footerLinks{
        font-size: 15px;
    }
    .footerLinks.not-links .icon {
        margin-right: 13px;
    }
    .footerLegalBlock .text {
        font-size: 15px;
    }
}

@media screen and (max-width: 991px) {
    .footer{
        padding: 50px 0;
    }
  .footerWrapper .logo-title {
       height: 45px;
    }
    .footerGrid .column .about {
        font-size: 14px;
    }
    .footerGrid .column .title{
        font-size: 16px;
    }
    .footerLinks {
        font-size: 14px;
    }
    
}

@media screen and (max-width: 768px) {
	.footerGrid {
		grid-template-columns: 1fr;
	}
   .footerGrid .column .title {
        height: 40px;
    }
    .footerGrid .column{
        margin-bottom: 40px;
    }
    .footerLinks.not-links {
        display: -webkit-flex;
        display: flex;
        margin-bottom: 10px;
    }
	.footerGrid .column .logo-title img {
		/* height: 100%; */
	}
}

@media screen and (max-width: 425px) {
	.footer {
		padding: 20px 5%;
	}
    .footerWrapper{
        padding-left: 0;
        padding-right: 0;
    }
	.footerWrapper .logo-title {
		height: 42px;
	 }
	.footerGrid .column .title {
		margin-bottom: 10px;
	}
	.footerGrid .column {
		margin-bottom: 40px;
	}
	.footerLinks {
		margin-bottom: 0;
		padding-right: 0;
		font-size: 14px;
	}
	.footerGrid {
		grid-template-columns: 1fr;
	}
	.footerLegalBlock {
		margin-top: 0;
		padding-top: 22px;
	}
	.footerLegalBlock .text {
        font-size: 12px;
    }
    .footerLinks.not-links .icon {
        font-size: 21px;
    }
	.footerGrid .column .logo-title img {
		height: 70%;
	}
}
@media screen and (max-width: 375px) {
	.footer {
		padding: 20px 10px;
	}
}

.dropdown-menu {
	left: -50%;
}

.dropdown-item {
    color: #FFB000;
	font-weight: 500;
}
.about-page-container{

}
.about-page-header{
    padding: 220px 10% 200px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}
.about-page-header .my-text-bg {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: #0000004f;
display: -webkit-flex;
display: flex;
-webkit-align-items: center;
        align-items: center;
-webkit-justify-content: center;
        justify-content: center;
}
.about-page-header h1 {
    text-align: center;
    color: #ffff;
    font-weight:700;
}
.our-history-wrapper {
    padding: 100px 10% 80px;
    background-color: #fffaf6;
}
.our-history-wrapper .main-title-div {
    /* text-align: center; */
}
.our-history-wrapper .main-title-div h2{
    color: #FFB000;
    font-weight: 600;
    margin-bottom: 45px;
}
.our-history-wrapper .main-title-div p{
    /* max-width: 60%; */
    margin: auto;
    text-align: left;
}
.milestone-wrapper{
    margin: 30px 0 20px;
    padding: 1px 0;
}
.milestone-wrapper .date-title{
    text-align: center;
    color: #1d123a;
    margin: 80px 0 50px;
    font-weight: 400;
}
.milestone-wrapper .date-title span {
    color: #FFB000;
    font-weight: 600;
}
.milestone-flex{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
}
.milestone-flex.my-left {
    -webkit-flex-direction: row-reverse;
            flex-direction: row-reverse;
}
.milestone-flex .my-text-wrapper{
    width: 42%;
}
.milestone-flex .my-text-wrapper h4{
    margin-bottom: 20px;
    font-weight: 600;
}
.milestone-flex  .my-img-wrapper{
    width: 42%;
    border-radius: 60% 43% 25% 32% / 76% 58% 55% 30%;
    overflow: hidden;
    box-shadow:3px 1px 10px 3px rgb(240 118 29 / 42%);
}

.milestone-flex  .my-img-wrapper.my-left{
    /* -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
     */
     border-radius:  43% 60%  32% 25%  / 58% 76% 30% 55%;
}
.milestone-flex  .my-img-wrapper img{
    width: 100%;
}

.our-team-wrapper{
    padding: 100px 10% 80px;;
    background-color: #fffaf6;
}
.our-team-wrapper .main-text-wrapper {
    margin-bottom: 50px;
}
.our-team-wrapper .main-text-wrapper h3{
    font-weight: 600;
    color: #FFB000;
}
.my-team-flex {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    box-shadow: 0px 1px 15px rgb(0 0 0 / 12%);
    border-radius: 10px;
    width: 100%;
    overflow: hidden;
    margin-bottom: 50px;
    background-color: #ffff;
}
.my-team-flex.my-right{
    -webkit-flex-direction: row-reverse;
            flex-direction: row-reverse;
}
.my-team-flex.my-right .my-img-wrapper{
    border: none;
    border-left: 6px solid #FFB000;

}
.my-team-flex .my-img-wrapper{
    width: 25%;
    border-right: 6px solid #FFB000;
    box-sizing: border-box;
}
.my-team-flex .my-img-wrapper img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  
}
.my-team-flex .my-text-wrapper{
    width: 75%;
    padding: 25px;
    min-height: 458px;
    box-sizing: border-box;
}
.my-team-flex .my-text-wrapper h4{
    margin-bottom: 20px;
    font-weight: 600;
}
.my-team-flex .my-text-wrapper h4 span  {
    color: #FFB000;
    font-weight: 400;
}


.home-story.no-padding {
    display: block;
    padding: 0;
    background: none;
}
.home-story.no-padding .my-column {
    width: 100%;
}

@media screen and (max-width: 1024px) {
    .about-page-header {
        padding: 170px 7% 160px;
    }
    .our-history-wrapper{
        padding: 80px 7%;
        overflow-x: hidden;
    }
    .our-team-wrapper{
        padding: 80px 7%;
        overflow-x: hidden;
    }
    .my-team-flex .my-text-wrapper{
        width: 65%;
    }
.my-team-flex .my-img-wrapper{
    width: 35%;
}
.my-team-flex .my-text-wrapper h4 {
    font-size: 21px;
}
    
}
@media screen and (max-width: 991px) {
    .our-history-wrapper .main-title-div h2{
        font-size: 28px;
    }
    .milestone-wrapper .date-title{
        font-size: 24px;
        margin: 70px 0 40px;
    }
    .milestone-flex .my-text-wrapper h4{
        font-size: 18px;
        margin-bottom: 10px;
    }
    .milestone-flex .my-text-wrapper, .milestone-flex .my-img-wrapper {
        width: 46%;
    }
}

@media screen and (max-width: 768px) {
    .about-page-header h1 {
        font-size: 36px;
    }
    .milestone-flex {
        display: block;
    }
    .milestone-flex .my-text-wrapper, .milestone-flex .my-img-wrapper {
        width: 100%;
    }
    .our-history-wrapper .main-title-div h2{
        margin-bottom: 35px;
    }
    .milestone-wrapper {
        margin: 30px 0 20px;
    }
    .milestone-flex .my-text-wrapper{
        margin-bottom: 30px;
    }
    .milestone-flex .my-img-wrapper{
        width: 80%;
        margin: auto;
    }
    .my-team-flex{
        display: block;
    }
    .my-team-flex .my-img-wrapper, 
    .my-team-flex .my-text-wrapper{
        width: 100%;
    }
    .my-team-flex .my-img-wrapper, .my-team-flex.my-right .my-img-wrapper{
        border: none;
        border-bottom: 6px solid #FFB000;
        height: 400px;
    }
    .our-team-wrapper .main-text-wrapper h3 {
        font-size: 24px;
        margin-bottom: 25px;
    }
    .our-team-wrapper {
        padding: 50px 7%;
    }
    .my-team-flex .my-text-wrapper{
        min-height: unset;
    }
}
@media screen and (max-width: 425px){
    .about-page-header {
        padding: 170px 15px 120px;
    }
.about-page-header h1 {
    font-size: 28px;
}
.our-history-wrapper {
    padding: 50px 15px;
}
.milestone-flex .my-img-wrapper {
    width: 100%;
}
.milestone-wrapper .date-title {
    margin: 40px 0 20px;
    font-size: 21px;
}
.our-history-wrapper .main-title-div p{
    font-size: 15px;
}
.our-history-wrapper .main-title-div h2{
    font-size: 24px;
    margin-bottom: 20px;
}
.milestone-flex .my-text-wrapper p {
    font-size: 15px;
}
.our-team-wrapper .main-text-wrapper h3 {
    font-size: 21px;
}
.our-team-wrapper {
    padding: 50px 15px;
}
.my-team-flex .my-text-wrapper{
    font-size: 15px;
    padding: 20px 12px;
}
.my-team-flex .my-text-wrapper h4{
    font-size: 18px;
}
.my-team-flex .my-text-wrapper h4 span{
    display: block;
}
.my-team-flex .my-img-wrapper, .my-team-flex.my-right .my-img-wrapper{
    height: 300px;
}
}


/* Mission Vission */

.our-mission-container{
    padding: 100px 10% 80px;
    background-color: #fffaf6;
}
.our-mission-container .main-title-div h2{
    color: #FFB000;
    font-weight: 600;
}
.our-mission-container .main-title-div{
    margin-bottom: 70px;
}
.my-mission-wrapper {
    display: -webkit-flex;
    display: flex;
    width: 100%;
}
.my-mission-wrapper .my-img-wrapper, .my-mission-wrapper .my-text-wrapper {
    width: 50%;
    min-height: 400px;
}
.my-mission-wrapper .my-img-wrapper img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.my-mission-wrapper .my-text-wrapper{
    padding: 60px 40px 40px;
    box-sizing: border-box;
    background-color: #ffff;
}
.my-text-wrapper.my-colored-Bg {
    background: #fffaf6;
}
.my-mission-wrapper .my-text-wrapper h3 {
    color: #1d123a;
    margin-bottom: 30px;
    font-weight: 600;
    color: #FFB000;
}
.my-mission-wrapper .my-text-wrapper ul {
    list-style-type: disc;
}
.my-mission-wrapper .my-text-wrapper li{
    margin-bottom: 10px;
}
.my-mission-wrapper .my-text-wrapper li span {
    font-weight: 600;
}
.my-mission-wrapper.vision{
    -webkit-flex-direction: row-reverse;
            flex-direction: row-reverse;
}
.mission-div-box-shadow {
    box-shadow:0px 0px 8px 3px rgb(240 118 29 / 12%);
    border-radius: 10px;
    overflow: hidden;
    /* margin-top: 30px; */
}
@media screen and (max-width: 1024px) {
    .our-mission-container{
        padding: 80px 7%;
        overflow-x: hidden;
    }
}
@media screen and (max-width: 991px) {
    .our-mission-container .main-title-div h2{
        font-size: 28px;
    }
    .my-mission-wrapper .my-text-wrapper h3{
        font-size: 24px;
        margin-bottom: 20px;
    }
    .my-mission-wrapper .my-text-wrapper li{
        margin-bottom: 2px;
    }
}
@media screen and (max-width: 768px) {
    .our-mission-container .main-title-div {
        margin-bottom: 50px;
    }
    .my-mission-wrapper{
        display: block;
        box-shadow:0px 0px 5px 2px rgb(240 118 29 / 12%);
        border-radius: 7px;
        overflow: hidden;
        margin-bottom: 40px;
    }
    .my-mission-wrapper .my-img-wrapper, .my-mission-wrapper .my-text-wrapper{
        width: 100%;
        min-height: unset;
        
    }
    .my-mission-wrapper .my-text-wrapper {
        padding: 40px 20px;
    }
    .my-mission-wrapper .my-img-wrapper{
        height: 300px;
    }
    .mission-div-box-shadow {
        box-shadow: none;
        border-radius: 0;
        overflow: visible;
    }
    .my-mission-wrapper .my-text-wrapper ul {
        list-style-type: none;
    }
}
@media screen and (max-width: 425px) {
    .our-mission-container{
        padding: 50px 15px;
    }
    .our-mission-container .main-title-div {
        margin-bottom: 30px;
    }
    .our-mission-container .main-title-div h2{
        font-size: 24px;
    }
    .my-mission-wrapper .my-img-wrapper {
        height: 250px;
    }
    .my-mission-wrapper .my-text-wrapper {
        padding: 25px 10px;
    }
    .my-mission-wrapper .my-text-wrapper h3 {
        font-size: 21px;
        margin-bottom: 12px;
    }
    .my-mission-wrapper .my-text-wrapper p , .my-mission-wrapper .my-text-wrapper li{
        font-size: 15px;
    }
}
.what-we-do-header{
    padding: 220px 10% 200px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}
.what-we-do-header.trading {
    background-position-y: -222px;
}
.what-we-do-header .my-text-bg {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
background-color: #0000004f;
display: -webkit-flex;
display: flex;
-webkit-align-items: center;
        align-items: center;
-webkit-justify-content: center;
        justify-content: center;
}
.what-we-do-header h1 {
    text-align: center;
    color: #ffff;
    font-weight:700;
}

.my-colored-Bg{
    background-color: #fffaf6;
}
.my-swapped {
    -webkit-flex-direction: row-reverse;
            flex-direction: row-reverse;
}
.home-story .my-column .my-contents .text-wrapper p.my-p-list{
    margin-bottom: 5px;
}
.home-story .my-column .my-contents .text-wrapper p.my-p-list .link {
    color: #FFB000;
    font-weight: 500;
    text-decoration: underline;
}
.home-story .my-column .my-contents .text-wrapper p.my-p-list .link:hover {
    color: #d69301;
}
.home-story .my-column .my-contents .text-wrapper ul li {
    list-style: disc;
    margin-bottom: 5px;
}
@media screen and (max-width: 1024px) {
    .what-we-do-header.trading {
        background-position-y: center;
    }
}

@media screen and (max-width: 768px){
    .my-swapped {
        -webkit-flex-direction: column;
                flex-direction: column;
    }
}
.home-story.conduct-wrapper {
    display: block;
    padding-top: 0;
    overflow-y: hidden;
}
.code-conduct {
    margin-bottom: 30px;
    overflow:hidden;
}
.code-conduct h4 {
    color: #FFB000;
    margin-bottom: 10px;
    font-size: 21px;
    font-weight: 500;
}
.code-conduct p {
    margin-bottom: 5px;
    text-align: justify;
}
.about-page-header.hse {
    background-position-y: -100px;
}

@media screen and (max-width: 768px) {
    .code-conduct {
        margin-bottom: 15px;
    }
    .code-conduct h4 {
        font-size: 20px;
    }
    .about-page-header.hse {
        background-position-y: -50px;
    }
}
@media screen and (max-width: 425px) { 
    .code-conduct p {
        font-size: 15px;
    }
    .code-conduct h4 {
        font-size: 18px;
    }
    .home-story .my-column .img-wrapper {
        padding: 160px 25px;
    }
    .about-page-header.hse {
        background-position-y: 0px;
        background-position-x: right;
    }
}

.home-story .my-column.contact-form {
    width: 60%;

}
.home-story .my-column.contact-form h3, .home-story .my-column.contact-details-wrapper h3 {
    font-weight: 600;
    color: #FFB000;
    margin-bottom: 45px;
}
form .file{
    padding: 0;
    border: none;
    border-radius: 0;
}
form input, form textarea {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid rgb(229, 230, 233);
    border-radius: 5px;
    margin-bottom: 20px;
    font-size: 16px;
    padding: 15px;
}
form button {
    background-color: #FFB000;
    color: #fff;
    padding: 12px 20px;
    border-radius: 5px;
    font-weight: 600;
    transition: 100ms ease-in-out;
    display: inline-block;
}
form button:hover {
    background-color: #d69200;
    transition: 100ms ease-in-out;
    text-decoration: none;
}
.home-story .my-column.contact-details-wrapper {
    width: 28%;
}

.my-column.contact-details-wrapper .footerLinks{
    color: inherit;
    font-size: 16px;
}
.my-column.contact-details-wrapper .footerLinks.not-links .icon{
    font-size: 28px;
}
.my-column.contact-details-wrapper .footerLinks.not-links{
    -webkit-align-items: flex-start;
            align-items: flex-start;
}
button:disabled {
    background: #dddddd;
  }
  button:disabled:hover {
    background: #dddddd;
  }
  .contact-form-success{
      color: #11bb44;
      border: 1px solid #11bb44 ;
      display: inline-block;
      padding: 5px;
      margin-bottom: 25px;
  }
  .contact-form-error {
    color: #bb1111;
    border: 1px solid #bb1111 ;
    display: inline-block;
    padding: 5px;
    margin-bottom: 25px;
  }

  @media screen and (max-width: 991px) {
    .home-story .my-column.contact-details-wrapper {
        width: 33%;
    }
  }

@media screen and (max-width: 768px) {
.home-story .my-column.contact-form h3, .home-story .my-column.contact-details-wrapper h3 {
        margin-bottom: 25px;
        font-size: 24px;
    }
    .home-story .my-column.contact-form {
        width: 100%;
    }
    .home-story .my-column.contact-details-wrapper {
        width: 100%;
    }

form input, form textarea {
    padding: 10px;
}
}


@media screen and (max-width: 425px){
    .home-story .my-column.contact-form h3, .home-story .my-column.contact-details-wrapper h3 {
    font-size: 21px;
}
    form button{
        padding: 10px 20px;
        font-weight: 500;
    }
}
