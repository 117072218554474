.home-story.conduct-wrapper {
    display: block;
    padding-top: 0;
    overflow-y: hidden;
}
.code-conduct {
    margin-bottom: 30px;
    overflow:hidden;
}
.code-conduct h4 {
    color: #FFB000;
    margin-bottom: 10px;
    font-size: 21px;
    font-weight: 500;
}
.code-conduct p {
    margin-bottom: 5px;
    text-align: justify;
}
.about-page-header.hse {
    background-position-y: -100px;
}

@media screen and (max-width: 768px) {
    .code-conduct {
        margin-bottom: 15px;
    }
    .code-conduct h4 {
        font-size: 20px;
    }
    .about-page-header.hse {
        background-position-y: -50px;
    }
}
@media screen and (max-width: 425px) { 
    .code-conduct p {
        font-size: 15px;
    }
    .code-conduct h4 {
        font-size: 18px;
    }
    .home-story .my-column .img-wrapper {
        padding: 160px 25px;
    }
    .about-page-header.hse {
        background-position-y: 0px;
        background-position-x: right;
    }
}